import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'
import { useAuction } from '@autobid/ui/composables/useAuction'

const PAGE_TYPES = {
  'auction-slug': 'carList',
  'item-slug': 'barometer',
  'item-slug-details': 'details',
  'auction-current-slug': 'barometerCurrent',
  'beamer-auctionId-interior': 'beamerInterior',
  'beamer-auctionId-exterior': 'beamerExterior',
  'beamer-auctionId': 'beamer'
} as const

export type AuctionPageType =
  | (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES]
  | 'carSearch'

export const useCarPageInfo = () => {
  const { listType, auctionId } = storeToRefs(useCarsStore())
  const { auction } = useAuction(auctionId.value)
  const router = useRouter()
  const { getLink } = useAuctionPagesLink()

  const pageType = computed(() => {
    if (listType.value === 'carSearch') return 'carSearch'
    const [baseRouteName] = router.currentRoute.value.name.split('___')

    return PAGE_TYPES[baseRouteName as keyof typeof PAGE_TYPES]
  })

  const backUrl = computed(() => {
    switch (pageType.value) {
      case 'carList':
        return undefined
      case 'details':
        return getLink({
          page: 'barometer',
          slug: router.currentRoute.value.params.slug
        })
      case 'barometer':
        return getLink({ page: 'car-list', slug: auction.value.slug })
      case 'barometerCurrent':
        return getLink({
          page: 'car-list',
          slug: router.currentRoute.value.params.slug
        })
    }

    return undefined
  })

  return { pageType, backUrl }
}
